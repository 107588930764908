// /var/www/website2023/web-design.rampagesoft.com/my-app/src/page/CRM.js
import React, { useEffect, useState } from 'react';
import Banner from '../banner';
import Text5icon from '../component/text5icon';
import Detailphoto from '../component/detailphoto';
import Detailphoto2 from '../component/detailphoto2';
import Spahotel from '../component/spahotel';
import Bgbanner from '../component/bg-banner';
import Slidebar from '../component/slidebar';
import Packagewebsite from '../component/package-website';
import FAQ from '../component/faq';
import TTTRAM from '../tttrampage';

function CRM() {
  const [data, setData] = useState({
    appServicesData: null,
    featuresStoriesData: null,
    crmBgBannerContent: null,
    crmDetailPhotoContent: null,
    crmSpaHotelContent: null,
    crmPackageContent: null,
    crmFaqData: null,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const endpoints = [
          "appServicesData.json",
          "featuresStories.json",
          "crmBgBannerContent.json",
          "crmDetailPhotoContent.json",
          "crmSpaHotelContent.json",
          "crmPackageContent.json",
          "crmFaqData.json",
        ];
  
        const promises = endpoints.map((endpoint) =>
          fetch(`${process.env.PUBLIC_URL}/data/crm/${endpoint}`).then((response) => {
            if (!response.ok) {
              throw new Error(`Failed to fetch ${endpoint}`);
            }
            return response.json();
          })
        );
  
        const [
          appServicesData,
          featuresStoriesData,
          crmBgBannerContent,
          crmDetailPhotoContent,
          crmSpaHotelContent,
          crmPackageContent,
          crmFaqData,
        ] = await Promise.all(promises);
  
        setData({
          appServicesData,
          featuresStoriesData,
          crmBgBannerContent,
          crmDetailPhotoContent,
          crmSpaHotelContent,
          crmPackageContent,
          crmFaqData,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchData();
  }, []);
  

  if (
    !data.appServicesData ||
    !data.featuresStoriesData ||
    !data.crmBgBannerContent ||
    !data.crmDetailPhotoContent ||
    !data.crmSpaHotelContent ||
    !data.crmPackageContent ||
    !data.crmFaqData
  ) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }

  const updatedCrmPackageContent = {
    ...data.crmPackageContent,
    packages: data.crmPackageContent.packages.map((pkg) => ({
      ...pkg,
      image: `/media/${pkg.image}`, // Prepend media path
    })),
  };

  return (
    <>
      <Banner />
      <Text5icon
        data={data.appServicesData.services || []}
        headingText={data.appServicesData.headingText}
      />
      <Detailphoto pageType="crm" />
      <Bgbanner heading={data.crmBgBannerContent.heading} />
      <Detailphoto2 content={data.crmDetailPhotoContent} />
      <Spahotel content={data.crmSpaHotelContent} />
      <Slidebar
        data={data.featuresStoriesData.services || []}
        heading={data.featuresStoriesData.headingText}
      />
      <Packagewebsite content={updatedCrmPackageContent} />
      <FAQ content={data.crmFaqData} />
      <TTTRAM />
    </>
  );
}

export default CRM;
